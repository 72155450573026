import React, { useState, useEffect, useContext } from 'react';
import InputMask from "react-input-mask";
import { Link, navigate } from 'gatsby';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/Seo';
import CtaButton from '@mui-components/ctaButton';
import { AppContext } from '@context'
import { Api } from '@components/utility/Api';
import Preloader from '@components/structure/Preloader';
//MUI
import {
	Box,
	TextField,
	Grid,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
} from '@mui/material';
import { checkValiation } from '@components/utility/Validation';
import { StaticImage } from 'gatsby-plugin-image';

const NarcolepsyUnbrandedRegistration = () => {
	const [getFields, setFields] = useState(() => []);
	const [webinars, setWebinars] = useState(() => []);
	const [loading, setLoading] = useState(() => false);
	let form = '';

	const {selectedWebinars, setSelectedWebinars} = useContext(AppContext);

	const handleSubmit = (e) => {
		e.preventDefault();
		form = e.target;
		const inputs = form.querySelectorAll('input');
		const headingErrorEl = form.querySelector('.heading-error') || null;
		let fieldValid = true;
		let formValid = true;
		let UUIDs = [];

		inputs.forEach(function (element) {
			const elementID = element.name;
			fieldValid = checkValiation(form, elementID, element.type, getFields);

			if (!fieldValid && headingErrorEl) {
				formValid = false;
			}
		});

		if(!formValid) {
			headingErrorEl.classList.add('show-error');
			headingErrorEl.focus();
		}
		else {
			headingErrorEl.classList.remove('show-error');
			setLoading(true);


			webinars.map((item, index) => {
				UUIDs.push(item[1].UUID)
			});

			// const registerData = {
			// 	"eventid": UUIDs.length > 1 ? UUIDs[0]+';'+UUIDs[1] : UUIDs[0],
			// 	"firstname": getFields['first-name'],
			// 	"lastname": getFields['last-name'],
			// 	"email": getFields['email-address'],
			// 	"phone":  getFields['phone-number'],
			// 	"zipcode":  getFields['zip-code'],
			// 	"reminderpref": getFields['text-reminder-group'],
			// 	"diagnosedwith": getFields['narcolepsy-interested-group'],
			// 	"iam": getFields['narcolepsy-patient-caregiver-group'],
			// 	"multipleauthorization": "true",
			// 	"treatment": getFields['child-diagnosed-group']
			// }
			

			// const success = response => {
			// 	if(response.success) {
			// 		navigate("/narcolepsy/confirmation")
			// 	}
			// 	else {
			// 		console.log(response);
			// 		setLoading(false);
			// 	}
				
			// }
			// const fail = error => {
			// 	setLoading(false);
			// 	console.log('Internal Error ', error);
			// }

			// Api.post_register(success, fail, registerData, 1);

			sendDataJazzCX(UUIDs);
		}
	};


	const sendDataJazzCX = (UUIDs) => {

		let indicationType = '';
		if(getFields['narcolepsy-interested-group'] === 'narcolepsywithc') {
			indicationType = 'Type 1 Narcolepsy (with Cataplexy)';
		}
		else if(getFields['narcolepsy-interested-group'] === 'narcolepsywoc') {
			indicationType = 'Type 2 Narcolepsy (without Cataplexy)';
		}
		else {
			indicationType = 'Uncertain';
		}

		const registerDataJazzCX = {}
		registerDataJazzCX.firstName = getFields['first-name'];
		registerDataJazzCX.lastName = getFields['last-name'];
		registerDataJazzCX.email = getFields['email-address'];
		registerDataJazzCX.zipCode = getFields['zip-code'];
		registerDataJazzCX.phoneNumber = getFields['phone-number']
		registerDataJazzCX.optInEmail = "Yes";
		registerDataJazzCX.optInSms = getFields['text-reminder-group'] === 'yes' ? 'Yes' : 'No';
		registerDataJazzCX.eventId = UUIDs.length > 1 ? UUIDs[0]+';'+UUIDs[1] : UUIDs[0];
		registerDataJazzCX.patientTypeDetails = getFields['narcolepsy-patient-caregiver-group'] === 'caregiver' ? 'Caregiver' : 'Patient'
		registerDataJazzCX.indicationType = indicationType;
		registerDataJazzCX.narcolepsyTypeDetails = getFields['child-diagnosed-group'] === 'yes' ? 'Yes' : 'No';
		registerDataJazzCX.dataSource = "JWPS-UNBRANDED-NARCOLEPSY";

		const success = response => {
			if(response.success) {
				navigate("/narcolepsy/confirmation");
			}
			else {
				console.log(response);
			}
			setLoading(false);
		}
		const fail = error => {
			setLoading(false);
			console.log('Internal Error ', error);
		}

		Api.post_register_CX(success, fail, registerDataJazzCX);
	}

	const handleChange = (e) => {
		let fields = getFields;

		fields[e.target.name] =
			e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		setFields(fields);

		if (form) {
			checkValiation(form, e.target.name, e.target.type, getFields);
		}
	};

	const removeClicked = (e, key) => {
		e.preventDefault();
		let newData = [...webinars];

		newData = newData.filter((item, index) => index !== key)
		setWebinars(newData);
		setSelectedWebinars(Object.fromEntries(newData));
		redirectCheck(newData);
	}

	const redirectCheck = (data) => {
		if (!data.length) {
			navigate("/narcolepsy");
		}
	}

	useEffect(() => {
		const isEDMode = window.location.href.indexOf('ed=1') > -1;
		if(isEDMode) {
			//screenshot
			const success = response => {
				let tempData = {};
				tempData[0] = {
						Date: response[0].Sessions[0].Date,
						ShortDescription: response[0].ShortDescription,
						Topic: response[0].Topic,
						UUID: response[0].Sessions[0].UUID,
				}
				setWebinars(Object.entries(tempData));
			}
			const fail = error => {
				console.log('Internal Error ', error);
			}
			Api.get_webinar(success, fail, "unbranded", "unbrandednarc");
		}
		else {
			redirectCheck(Object.entries(selectedWebinars));
			setWebinars(Object.entries(selectedWebinars));
		}
	}, []);

	return (
		<Layout pageType='narcolepsy-unbranded' className='narcolepsyUnbranded no-tabs' pixelCode='Start Registration'>
			<Seo title='Narcolepsy Webinar Registration | Jazz Webinars' description="If you're living with or caring for someone with narcolepsy, register today to gain access to the upcoming narcolepsy live webinars and educational programs." />

			<div>
				<StaticImage
					className='test-desktop'
					src='../../../static/images/narcolepsy-unbranded-hero.jpg'
					alt='Narcolepsy Hero Banner Desktop on register page'
				/>
				<StaticImage
					className='test-mobile '
					src='../../../static/images/narcolepsy-unbranded-hero-mobile.jpg'
					alt='Narcolepsy Hero Banner Mobile on register page'
				/>
				<section className='container firstSection'>
					<Grid container>
						<Grid item xs={12}>
							<div className='back-link-background'>
								<span className='back-link'>
									&#8249;{' '}
									<Link to='/narcolepsy'>
										Back to Webinar Library
									</Link>
								</span>
							</div>

							<div className='lv-copy'>
								<h1>
									<span>Stay up-to-date on the</span>
									Symptoms <br className='desktopOnly' />
									and Impact <br className='desktopOnly' />
									of Narcolepsy
								</h1>
							</div>
						</Grid>
					</Grid>
				</section>
			</div>
			<section className='container narcolepsyUnbranded'>
				<div>
					<h2 className='header centerText'>Just one last step!</h2>
					<div className='gradient-divider center'>
						<hr />
					</div>
					<p className='copy centerText'>
						To register for your live webinars, please complete the form
						below.<span className='desktopOnlyContent'> You can view, edit, or remove the webinars you’ve selected
						in the right-hand column.</span>
					</p>
				</div>

				<div className='selectWebinarSection formRight spaceTop'>
					<p className='label'>Your Selected Webinars</p>
					{webinars ? webinars.map((item, key) => (
					<div className='selectedWebinarItem' key={key}>
						<a href="#" onClick={(e) => {
							removeClicked(e, key);
						}}>Remove</a>
						<Link to='/narcolepsy'>Edit</Link>
						<p className='header2'>{item[1].Topic}</p>
						<p className='body2'>{item[1].ShortDescription || 'No description provided'}</p>
						<p className='timeDate'>TIME &amp; DATE</p>
						<p className='date'>{item[1].Date}</p>
					</div>
					)) : null}
				</div>
				<form
					name='registration'
					method='post'
					onSubmit={handleSubmit}>
					<p className='error-label heading-error' tabIndex='-1'>
						<strong>
							Please be sure to complete or correct the required field(s).
						</strong>
					</p>
					<p className='fieldsRequired'>
						All fields are required unless otherwise specified.
					</p>
					<div className=''>
						<Grid container justifyContent={'space-between'}>
							<Grid item xs={12} className='spaceTop'>
								<div className='formLeft'>
									<Box
										className='formLeftWidth'
										sx={{
											'& > :not(style)': { m: 1, width: '100%' },
										}}
										noValidate
										autoComplete='off'>
										<label htmlFor='first-name'>First Name</label>
										<p className='error-label first-name'>
											Please enter your First Name.
										</p>
										<TextField
											id='first-name'
											name='first-name'
											variant='outlined'
											color='primary'
											inputProps={{ maxLength: 50 }}
											onChange={handleChange}
										/>
										<label htmlFor='last-name'>Last Name</label>
										<p className='error-label last-name'>
											Please enter your Last Name.
										</p>
										<TextField
											id='last-name'
											name='last-name'
											variant='outlined'
											color='primary'
											inputProps={{ maxLength: 50 }}
											onChange={handleChange}
										/>
										<label htmlFor='email-address'>
											Email Address
										</label>
										<p className='error-label email-address'>
											Please enter a valid Email Address.
										</p>
										<TextField
											id='email-address'
											name='email-address'
											variant='outlined'
											color='primary'
											inputProps={{ maxLength: 50 }}
											onChange={handleChange}
										/>
										<label htmlFor='phone-number'>
											Mobile Phone Number
										</label>
										<p className='error-label phone-number'>
											Please enter a valid Mobile Phone Number.
										</p>
										<TextField
											id='phone-number'
											name='phone-number'
											variant='outlined'
											color='primary'
											inputProps={{ maxLength: 10 }}
											onChange={handleChange}
										/>
										<label htmlFor='zip-code'>ZIP Code</label>
										<p className='error-label zip-code'>
											Please enter a valid ZIP Code.
										</p>
										<InputMask
											mask="99999"
											onChange={handleChange}
											maskChar=""
										>
											{() => <TextField
												id='zip-code'
												name='zip-code'
												variant='outlined'
												color='primary'
											/>}
										</InputMask>
									</Box>
									<div className='formLeftWidth'>
										<p
											id='text-reminder'
											className='textReminderText'>
											<strong>
												Would you like a text reminder?
											</strong>
										</p>
										<p>
											Messaging and data rates may apply. Reply STOP
											to 98637 to cancel and/or HELP for help. Up to
											10 msg/mo.{' '}
											<a href='/terms-conditions' target={"_blank"}>SMS Terms &amp; Conditions</a>
										</p>
										<p className='error-label radio-padding-fix text-reminder-group'>
											Please select an option.
										</p>
										<FormControl>
											<RadioGroup
												row
												onChange={handleChange}
												aria-labelledby='text-reminder'
												name='text-reminder-group'>
												<FormControlLabel
													value='yes'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='Yes'
												/>
												<FormControlLabel
													value='no'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='No'
												/>
											</RadioGroup>
										</FormControl>
									</div>
									<div className='formLeftWidth'>
										<p id='child-diagnosed'>
											<strong>
												Have you or your child been diagnosed with
												narcolepsy?
											</strong>
										</p>
										<p className='error-label radio-padding-fix child-diagnosed-group'>
											Please select an option.
										</p>
										<FormControl>
											<RadioGroup
												row
												onChange={handleChange}
												aria-labelledby='child-diagnosed'
												name='child-diagnosed-group'>
												<FormControlLabel
													value='yes'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='Yes'
												/>
												<FormControlLabel
													value='no'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='No'
												/>
											</RadioGroup>
										</FormControl>
									</div>
									<div className='formLeftWidth'>
										<p
											className='bold'
											id='narcolepsy-patient-caregiver'>
											Are you a narcolepsy patient or caregiver?
										</p>
										<p className='error-label radio-padding-fix narcolepsy-patient-caregiver-group'>
											Please select an option.
										</p>
										<FormControl>
											<RadioGroup
												row
												onChange={handleChange}
												aria-labelledby='narcolepsy-patient-caregiver'
												name='narcolepsy-patient-caregiver-group'>
												<FormControlLabel
													value='patient'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='Patient'
												/>
												<FormControlLabel
													value='caregiver'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='Caregiver'
												/>
											</RadioGroup>
										</FormControl>
									</div>

									<div className='' id='narcolepsy-interested'>
										<p className='bold'>
											What type of narcolopsy are you interested in?
										</p>
										<p className='error-label radio-padding-fix narcolepsy-interested-group'>
											Please select an option.
										</p>
										<RadioGroup
											onChange={handleChange}
											name='narcolepsy-interested-group'
											aria-labelledby='narcolepsy-interested'>
											<FormControlLabel
												value='narcolepsywithc'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label='Type 1 Narcolepsy (with Cataplexy)'
											/>
											<FormControlLabel
												value='narcolepsywoc'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label='Type 2 Narcolepsy (without Cataplexy)'
											/>
											<FormControlLabel
												value='uncertain'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label='Uncertain'
											/>
										</RadioGroup>
										<div className='consentCheckboxContainer'>
											<FormControl
												required
												//error={error}
												component='fieldset'
												sx={{ m: 3 }}
												onChange={handleChange}
												variant='standard'
												className='checkboxGroup'>
												<FormGroup>
													<FormControlLabel
														control={
															<Checkbox
																name='consent'
																color='primary'
																sx={{
																	'& .MuiSvgIcon-root':
																		{
																			fontSize: 32,
																		},
																}}
															/>
														}
														label=''
													/>
													<p className='consent-copy'>
														By checking this box, you confirm
														that you are 18 years of age or
														older and a resident of the US.
														You understand that Jazz
														Pharmaceuticals and its business
														partners will collect the personal
														information you provide and use it
														to manage your enrollment in the
														program. You are indicating that
														you would like to receive
														information from Jazz
														Pharmaceuticals about educational
														programs, products, and services.
														To learn how Jazz Pharmaceuticals
														will use and protect your personal
														information, please review our{' '}
														<a href='https://www.jazzpharma.com/privacy-statement/' target="_blank">
															Privacy Statement
														</a>
														. You can opt out at any time.
													</p>
												</FormGroup>
												<br />
												<p className='error-label consent'>
													Please check the box above to
													continue.
												</p>
											</FormControl>
											<div className='privacyStatmentLink'>
												<a href='https://www.jazzpharma.com/privacy-statement/' target="_blank">
													Privacy Statement
												</a>
											</div>
										</div>
									</div>

									<div className='registerButton'>
										{!loading ? 
										<CtaButton
											type='submit'
											title='REGISTER'
											class='cta main-cta registerButtonItem'
										/>
										: <Preloader/>}
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</form>
			</section>
		</Layout>
	);
};

export default NarcolepsyUnbrandedRegistration;
